import ApiRequestError from '../utils/errors/ApiRequestError';
import ResponseFailedError from '../utils/errors/ResponseFailedError';
import { Getters as ApiGetters } from './apiStore';

export const Actions = {
  retrieveEvents: "retrieveEvents",
  retrieveNotLinkedEvents: "retrieveNotLinkedEvents",
  retrieveEvent: "retrieveEvent",
  createEvent: "createEvent",
  updateEvent: "updateEvent",
  updateEventImage: "updateEventImage",
  deleteEvent: "deleteEvent",
  rescheduleEvent: "rescheduleEvent",
};

// Create a new store instance.
const eventsModule = {
  actions: {
    async [Actions.retrieveEvents](context, filters = {}) {
      try {
        const axiosResponse = await context.getters[ApiGetters.eventController].apiEventsGet(
          filters.userId,
          filters.withEventUsers,
          filters.withEventLocation,
          filters.allEvents,
          filters.onlyFutureEvents,
          filters.archived,
          filters.searchField,
          filters.searchString,
          filters.pageSize,
          filters.pageNumber,
          filters.sortBy,
          filters.sortOrder,
          filters.publicOnly
        );

        const response = axiosResponse.data;
        if (response.status == "success") {
          const events = response.data;

          return events;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.retrieveNotLinkedEvents](context) {
      try {
        const axiosResponse = await context.getters[ApiGetters.eventController].apiEventsNotLinkedGet();

        const response = axiosResponse.data;
        if (response.status == "success") {
          const events = response.data;

          return events;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.retrieveEvent](context, eventId) {
      try {
        const axiosResponse = await context.getters[ApiGetters.eventController].apiEventsIdGet(eventId);

        const response = axiosResponse.data;
        if (response.status == "success") {
          const Event = response.data;

          return Event;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.deleteEvent](context, eventId) {
      try {
        const axiosResponse = await context.getters[ApiGetters.eventController].apiEventsIdDelete(eventId);

        const response = axiosResponse.data;
        if (response.status == "success") {
          const Event = response.data;

          return Event;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.createEvent](context, Event) {
      try {
        const axiosResponse = await context.getters[ApiGetters.eventController].apiEventsPost(Event);

        const response = axiosResponse.data;
        if (response.status == "success") {
          const Event = response.data;

          return Event;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.updateEventImage](context, { id, formData }) {
      try {
        const axiosResponse = await context.getters[ApiGetters.eventController].apiEventsIdImagePut(id, {
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        const response = axiosResponse.data;
        if (response.status == "success") {
          const Event = response.data;

          return Event;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.updateEvent](context, Event) {
      try {
        const axiosResponse = await context.getters[ApiGetters.eventController].apiEventsIdPut(Event.id, Event);

        const response = axiosResponse.data;
        if (response.status == "success") {
          const Event = response.data;

          return Event;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.rescheduleEvent](context, Event) {
      try {
        const axiosResponse = await context.getters[ApiGetters.eventController].apiEventsReschedulePost(Event);

        const response = axiosResponse.data;
        if (response.status == "success") {
          const Event = response.data;

          return Event;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
  },
};

export default eventsModule;