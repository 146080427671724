export default {
  login: {
    name: 'login',
    path: '/login',
  },
  forgotPassword: {
    name: 'forgotPassword',
    path: '/forgotPassword',
  },
  resetPassword: {
    name: 'resetPassword',
    path: '/resetPassword/:code',
  },
  homePage: {
    name: 'home page',
    path: '/',
  },
  usersInvitations: {
    list: {
      name: 'users invitations list',
      path: '/usersInvitations',
    },
    form: {
      name: 'users invitations form',
      path: '/usersInvitations/create',
    },
  },
  users: {
    list: {
      name: 'users list',
      path: '/users',
    },
    create: {
      name: 'users create form',
      path: '/users/create',
    },
    details: {
      name: 'users details',
      path: '/users/:id',
    },
    update: {
      name: 'users update form',
      path: '/users/:id/update',
    },
  },
  locations: {
    list: {
      name: 'locations list',
      path: '/locations',
    },
    details: {
      name: 'location details',
      path: '/locations/:id',
    },
    create: {
      name: 'locations create form',
      path: '/locations/create',
    },
    update: {
      name: 'locations update form',
      path: '/locations/:id/update',
    },
  },
  events: {
    list: {
      name: 'events list',
      path: '/events',
    },
    details: {
      name: 'event details',
      path: '/events/:id',
    },
    create: {
      name: 'events create form',
      path: '/events/create',
    },
    update: {
      name: 'events update form',
      path: '/events/:id/update',
    },
  },
  eventUsers: {
    list: {
      name: 'event users list',
      path: '/eventUsers',
    },
    create: {
      name: 'event users create form',
      path: '/eventUsers/create',
    },
  },
  instruments: {
    list: {
      name: 'instruments list',
      path: '/instruments',
    },
    details: {
      name: 'instruments details',
      path: '/instruments/:id',
    },
    create: {
      name: 'instruments create form',
      path: '/instruments/create',
    },
    update: {
      name: 'instruments update form',
      path: '/instruments/:id/update',
    },
  },
  equipment: {
    list: {
      name: 'equipment list',
      path: '/equipment',
    },
    create: {
      name: 'equipment create form',
      path: '/equipment/create',
    },
    update: {
      name: 'equipment update form',
      path: '/equipment/:id/update',
    },
  },
  notifications: {
    send: {
      name: 'notifications',
      path: '/notifications',
    },
  },
  pageNotFound: {
    home: {
      name: 'page not found',
      path: "*",
    },
  },
};