<template>
  <header class="navbar navbar-expand-lg bd-navbar sticky-top" style="background-color: #e3f2fd">
    <nav
      class="container-xxl bd-gutter flex-wrap flex-lg-nowrap justify-content-between"

    >
      <router-link :to="{ name: $routes.homePage.name }" class="navbar-brand">
        Banda App
      </router-link>
      Welcome {{ loggedInUser.name }} {{ loggedInUser.surname }} ({{
        loggedInUser.email
      }})
      <div>
        <button class="btn btn-danger" @click="logOut">Log out</button>
      </div>
    </nav>
  </header>

</template>
<script>
import { Actions, Getters } from "../stores/store";
export default {
  data() {
    return {
      loggedInUser: {},
    };
  },
  created() {
    this.getLoggedInUser();
  },
  methods: {
    async getLoggedInUser() {
      this.loggedInUser = await this.$store.getters[Getters.storage.user];
    },
    async logOut() {
      await this.$store.dispatch(Actions.global.logout);
      this.$router.replace({name: this.$routes.login.name});
    },
  },
};
</script>