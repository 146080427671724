import ApiRequestError from '../utils/errors/ApiRequestError';
import ResponseFailedError from '../utils/errors/ResponseFailedError';
import { Getters as ApiGetters } from './apiStore';

export const Actions = {
  retrieveEventUsers: "retrieveEventUsers",
  retrieveEventUser: "retrieveEventUser",
  createEventUser: "createEventUser",
  updateEventUsers: "updateEventUsers",
  deleteEventUser: "deleteEventUser",
};

// Create a new store instance.
const eventUsersModule = {
  actions: {
    async [Actions.retrieveEventUsers](context, filters = {}) {
      try {
        const axiosResponse = await context.getters[ApiGetters.eventUserController].apiEventUserGet(
          filters.userId, 
          filters.searchField,
          filters.searchString,
          filters.pageSize, 
          filters.pageNumber, 
          filters.sortBy, 
          filters.sortOrder, 
          filters.withIntruments,
          filters.attendanceStatusFilter,
          filters.withEquipment,
        );

        const response = axiosResponse.data;
        if (response.status == "success") {
          const eventUsers = response.data;
  
          return eventUsers;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.retrieveEventUser](context, eventUserId) {
      try {
        const axiosResponse = await context.getters[ApiGetters.eventUserController].apiEventUserIdGet(eventUserId);
  
        const response = axiosResponse.data;
        if (response.status == "success") {
          const eventUser = response.data;
  
          return eventUser;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.deleteEventUser](context, eventUserId) {
      try {
        const axiosResponse = await context.getters[ApiGetters.eventUserController].apiEventUserIdDelete(eventUserId);
  
        const response = axiosResponse.data;
        if (response.status == "success") {
          const eventUser = response.data;
  
          return eventUser;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.createEventUser](context, EventUser) {
      try {
        const axiosResponse = await context.getters[ApiGetters.eventUserController].apiEventUserPost(EventUser);
  
        const response = axiosResponse.data;
        if (response.status == "success") {
          const eventUser = response.data;
  
          return eventUser;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.updateEventUsers](context, multipleEventUserPut) {
      try {
        const axiosResponse = await context.getters[ApiGetters.eventUserController].apiEventUserPut(multipleEventUserPut);
  
        const response = axiosResponse.data;
        if (response.status == "success") {
          const eventUser = response.data;
  
          return eventUser;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
  },
};

export default eventUsersModule;